
#root {
  background-color: #fff;
}
.ProseMirror:focus {
    outline: none;
}

.ProseMirror h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0.25em;
  margin-top: 0.25em;
}

.ProseMirror   span[data-comment] {
  background: rgb(160 232 48 / 31%);
  border-bottom: 2px solid rgb(160 232 48);
  padding-bottom: 2px;
}

@keyframes fade-in {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}


/* usage samples with adjusted letter spacings 
h1 {
  font-size: 56px;
}
h2 {
  font-size: 40px;
  letter-spacing: 0.01em;
}
h3 {
  font-size: 36px;
  letter-spacing: 0.015em;
}
h4 {
  font-size: 28px;
  letter-spacing: 0.02em;
}
h5 {
  font-size: 20px;
  letter-spacing: 0.03em;
}
h6 {
  font-size: 16px;
  letter-spacing: 0.04em;
}
.quote {
  font-size: 24px;
  letter-spacing: 0.02em;
}
.smaller_cal_sans {
  font-size: 18px;
  letter-spacing: 0.045em;
}
*/